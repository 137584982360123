import React, { Component } from "react";
import Fade from "react-reveal";
import CustomModal from "./Custom/CustomModal";

let id = 0;
class Portfolio extends Component {
  state = {
    isModalOpen: false,
    project: null
  };
  render() {
    if (!this.props.data) return null;

    const onImageClick = project => {
      debugger;
      this.setState({ isModalOpen: true, project });
    };

    const closeModal = () => {
      this.setState({ isModalOpen: false });
    };

    const projects = this.props.data.projects.map(function (projects) {
      let projectImage = "images/portfolio/" + projects.image;

      return (
        <div key={id++} className="columns portfolio-item">
          <div className="item-wrap">
            <button onClick={() => onImageClick(projects)}>
              <img alt={projects.title} src={projectImage} />
            </button>
            <h3 style={{ textAlign: "center" }}>{projects.title}</h3>
          </div>
        </div>
      );
    });

    return (
      <section id="portfolio">
        <Fade left duration={1000} distance="60px">
          <CustomModal
            closeModal={closeModal}
            isModalOpen={this.state.isModalOpen}
            project={this.state.project}
          />
          <div className="row">
            <div className="twelve columns collapsed">
              <h1 style={{ color: "white" }}>Check Out Some of my Learning Projects.</h1>

              <div
                id="portfolio-wrapper"
                className="bgrid-halves s-bgrid-thirds cf"
              >
                {projects}
              </div>
            </div>
          </div>
        </Fade>
      </section>
    );
  }
}

export default Portfolio;
