import React, { userState, useState } from "react";
import Fade from "react-reveal";
import CustomModal from "./Custom/CustomModal";

let id = 0;

export default props => {
  let [isModalOpen, toggleModal] = useState(false);
  let [selectedActivity, setActivity] = useState(null);

  if (!props.data) return null;

  let onImageClick = activity => {
    toggleModal(true);
    setActivity({ ...activity });
  };

  let closeModal = () => {
    toggleModal(false);
  };

  let activities = props.data?.activities.map(activity => {
    let activityImage = "images/cricket/" + activity.image;
    return (
      <div key={id++} className="columns portfolio-item">
        <div className="item-wrap">
          <button onClick={() => onImageClick(activity)}>
            <img alt={activity.title} src={activityImage} />
          </button>
          <h3 style={{ textAlign: "center" }}>{activity.title}</h3>
        </div>
      </div>
    );
  });

  return (
    <section id="extracurricular">
      <Fade left duration={1000} distance="60px">
        <CustomModal
          closeModal={closeModal}
          isModalOpen={isModalOpen}
          project={selectedActivity}
        />
        <div className="row">
          <div className="twelve columns collapsed">
            <h3 style={{ textAlign: "center" }}>
              Extracurriculars And Achievements
            </h3>
            <div
              id="portfolio-wrapper"
              className="bgrid-halves s-bgrid-thirds cf"
            >
              {activities}
            </div>
          </div>
        </div>
      </Fade>
    </section>
  );
};
