import React from "react";
import Zmage from "react-zmage";
import { makeStyles } from "@material-ui/core/styles";
import ImageList from "@material-ui/core/ImageList";
import ImageListItem from "@material-ui/core/ImageListItem";

const useStyles = makeStyles(theme => ({
  root: {
    display: "flex",
    flexWrap: "wrap",
    justifyContent: "space-around",
    overflow: "hidden",
    backgroundColor: theme.palette.background.paper
  },
  imageList: {
    width: 1000
  }
}));

export default props => {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <ImageList
        rowHeight={props.rowHeight || 320}
        className={classes.imageList}
        cols={2}
      >
        {props.images.map(item => (
          <ImageListItem key={item.img} cols={1}>
            <div className="grid-image" style={{ padding: "2%" }}>
              <Zmage src={props.imageRoute + item} alt={item} />
            </div>
          </ImageListItem>
        ))}
      </ImageList>
    </div>
  );
};
