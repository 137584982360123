import React from "react";
import Modal from "react-modal";
import { Button, IconButton } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import GridImages from "./GridImages";

const customStyles = {
  content: {
    position: "absolute",
    top: "15%",
    left: "15%",
    right: "15%",
    bottom: "15%"
  },
  overlay: {
    backdropFilter: "blur(10px)",
    backgroundColor: "#D3D3D3"
  }
};

export default props => {
  let { project } = props;

  return (
    <Modal
      closeTimeoutMS={200}
      isOpen={props.isModalOpen}
      onRequestClose={props.closeModal}
      style={customStyles}
    >
      <div>
        <IconButton
          aria-label="close"
          color="secondary"
          onClick={props.closeModal}
        >
          <CloseIcon />
        </IconButton>
        <div
          style={{
            //  textAlign: "center",
            paddingLeft: "10%",
            paddingRight: "10%",
            paddingTop: "5%",
            paddingBottom: "5%"
          }}
        >
          <h2>{project?.title}</h2>
          {project?.body.customDescription ? (
            <ol style={{ listStyleType: 'square' }}>
              {project?.body.customDescription.split("\n").map(description => {
                return <li>{description}</li>;
              })}
            </ol>
          ) : (
            <p>{project?.body.description}</p>
          )}
          <GridImages
            images={project?.body.images}
            rowHeight={project?.body.rowHeight}
            imageRoute={project?.body.imageRoute}
          />
          <div style={{}}>
            {project?.body.urls &&
              project.body.urls.map(url => {
                return (
                  <a
                    target="_blank"
                    href={url.url}
                    style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5 }}
                  >
                    <Button
                      variant="contained"
                      color="primary"
                      style={{ fontSize: 15 }}
                    >
                      {url.title}
                    </Button>
                  </a>
                );
              })}
            {project?.body.githubURLS &&
              project?.body.githubURLS.map(url => {
                return (
                  <a
                    target="_blank"
                    href={url.url}
                    style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5 }}
                  >
                    <Button
                      variant="outlined"
                      color="primary"
                      style={{ fontSize: 15 }}
                    >
                      {url.title}
                    </Button>
                  </a>
                );
              })}
            {project?.body.apkURL &&
              project.body.apkURL.map(url => {
                return (
                  <a
                    target="_blank"
                    href={url.url}
                    style={{ paddingLeft: 5, paddingRight: 5, paddingTop: 5 }}
                  >
                    <Button
                      variant="outlined"
                      color="secondary"
                      style={{ fontSize: 15 }}
                    >
                      {url.title}
                    </Button>
                  </a>
                );
              })}
            {/* <div style={{ paddingTop: 10 }}>
              <Button
                variant="contained"
                color="secondary"
                onClick={props.closeModal}
                style={{ fontSize: 15 }}
              >
                Close
              </Button>
            </div> */}
          </div>
        </div>
      </div>
    </Modal >
  );
};
